import logo from "./logo.svg";
import "./App.css";
import Menu from "./pages/Menu/Menu";
import Header from "./components/Header/Header";
import { Box, CssBaseline } from "@mui/material";
import CartItemsProvider from "./context/CartItemsProvider";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/ContactUs/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
const App = () => {
  const [shoppingMode, setShoppingMode] = useState(true);
  useEffect(() => {
    if (window.location.pathname === "/staticmenu") setShoppingMode(false);
    else setShoppingMode(true);
  }, []);
  return (
    <Box className="App">
      <ToastContainer />
      <CartItemsProvider>
        <BrowserRouter>
          <CssBaseline />

          <Header shoppingMode={shoppingMode} />
          <Box component="main" sx={{ py: 14, px: 2 }}>
            <Routes>
              {/* Redirect from root path to /menu */}
              <Route path="/" element={<Navigate to="/menu" replace />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/staticmenu" element={<Menu />} />
            </Routes>
          </Box>
        </BrowserRouter>
      </CartItemsProvider>
    </Box>
  );
};

export default App;
